document.addEventListener('DOMContentLoaded', function () {
    const tabs = document.querySelectorAll('.js-offer-tab');
    const offers = document.querySelectorAll('.js-offers-offer');
    const tables = document.querySelectorAll('.js-offers-table');
    const detailsBtn = document.querySelectorAll('.js-offer-detail');
    const table = document.querySelector('.js-offer-table');

    function resetTabs() {
        tabs.forEach(tab => tab.classList.remove('is-active'));
        offers.forEach(offer => offer.classList.remove('is-active'));
        tables.forEach(table => table.classList.remove('is-active'));
    }

    function activateTab(index) {
        resetTabs();
        tabs[index].classList.add('is-active');
        offers[index].classList.add('is-active');
        tables[index].classList.add('is-active');
    }

    function tableToggle() {
        table.classList.toggle('hide');
        table.classList.toggle('show');
    }

    detailsBtn.forEach(btn => {
        btn.addEventListener('click', function () {
            table.classList.remove('hide');
            table.classList.add('show');
        });
    });

    tabs.forEach((tab, index) => {
        tab.addEventListener('click', function () {
            activateTab(index);
            tableToggle();
        });
    });

    if (tabs.length > 0) {
        activateTab(0);
    }
});